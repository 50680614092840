import gql from "graphql-tag";

export const SteamBotFragment = gql`
  fragment SteamBot on SteamBot {
    _id
    username
    password
    apiKey
    enabled
    identitySecret
    sharedSecret
    partnerUserId
    proxy
    steamItemsCount
    steamItemsPrice
    tradeUrl
    type
    proxyId
    partnerUser {
      name
    }
  }
`;

export const PaginationFields = `
    totalDocs
    limit
    totalPages
    page
    pagingCounter
    hasPrevPage
    hasNextPage
    nextPage
    prevPage
  
`;
